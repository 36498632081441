<template>
  <b-card>
    <b-card-body>
      Inicio
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'Inicio',
}
</script>

<style scoped>

</style>
